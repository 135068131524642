import React, { ReactElement } from "react";
import MainBanner from "@components/debtreliefcorner/mainBanner/mainBanner";
import { GetServerSideProps } from "next";
import { getPageProps } from "@services/initial-calls";
import { useDomainContext } from "@hooks/useDomainContext";
import DebtReliefCornerWrapper from "@components/layouts/debtReliefCornerWrapper";
import OffersList from "@components/shared/offersList";
import WhySeekDebtRelief from "@components/debtreliefcorner/whySeekDebtRelief";
import DebtReliefMean from "@components/debtreliefcorner/debtReliefMean";
import UnlockAdvantages from "@components/debtreliefcorner/unlockAdvantages";
export default function DebtReliefCornerHome(): ReactElement {
    const { domain } = useDomainContext();

    return (
        <DebtReliefCornerWrapper>
            <MainBanner />
            <section style={{ padding: "0 15px" }}>
                <OffersList offers={domain?.defaultOffers} />
            </section>
            <WhySeekDebtRelief />
            <DebtReliefMean />
            <UnlockAdvantages />
        </DebtReliefCornerWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
